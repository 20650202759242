import React, {useState, useContext,useEffect}  from 'react';
import {useNavigate } from "react-router-dom";
import UserContext from '../../context/user/userContext';
import AlertContext from '../../context/alert/alertContext';
import AuthContext from '../../context/auth/authContext';
import { Button, Form,Row, Col} from "react-bootstrap";
import ROLES from '../../utils/roles.js';
import moment from 'moment';

import OrganisationContext from '../../context/organisation/organisationContext';

import FileContext from '../../context/file/fileContext';

import UserFormImageItem from './UserFormImageItem';

const UserForm = ({user,closeForm,newUser}) => {

    const navigate = useNavigate();
    
    const userContext = useContext(UserContext);
    const alertContext = useContext(AlertContext);
    const organisationContext = useContext(OrganisationContext);
  
    const {current, getUserById, addUser, updateUser, error} = userContext;
    const {setAlert} = alertContext;
    const {organisations,getOrganisations} = organisationContext;

    const authContext = useContext(AuthContext);
    const {isSuperAdmin} = authContext;
    
    const [userData, setUserData] = useState({
        name:'',
        lastName: '',
        email: '',
        role: 4,
        organisation: null,
        password: '',
        isCoach: false,
        isProjectManager: false,
        username: '',
        jobTitle: '',
        registrationOpen: false
    })

    const [submitted,setSubmitted] = useState(false);
    const {name, lastName, email, role, organisation, password, isCoach, isProjectManager ,username,jobTitle,registrationOpen} = userData;

    const fileContext = useContext(FileContext);
    const {images, getImages} = fileContext;

    const [showPicker, setShowPicker] = useState(false);
    const [showPassword,setShowPassword] = useState(false);

    const toggleShowPassword = (e) =>{
        e.preventDefault();
        setShowPassword(!showPassword);
    }

    const [selectedIndex, setSelectedIndex] = useState(-1);
    const onImageClick = (newIndex,imageId) =>{
       if(newIndex === selectedIndex){
           newIndex=(-1);
           imageId = null;
       }
       setSelectedIndex(newIndex);
       setUserData({
           ...userData, 
           image: imageId
       })
    }

    useEffect( () =>{

        if(!newUser){
            setUserData(user);
        }
        if(organisations===null || !organisations.length){
            getOrganisations();            
        }
        if(!images){
            getImages();
        }
        // eslint-disable-next-line
    },[])

    useEffect( () =>{

        if(error !== null){
            setAlert(error, 'danger');
        }

        if(!error && submitted){
            closeForm();
            setSubmitted(false);
        }
    },[error, submitted, userContext])

    const onSubmit = e =>{
        e.preventDefault();

        if(userData.password && hasWhiteSpace(userData.password)){

            setAlert('Warning: Your password contains Whitespace', 'danger');

            alert('Your password contains Whitespace');

            return;
        }

        if(newUser){
            addUser({...userData, email: userData.email.toLowerCase()});
        }
        else{
            updateUser({...userData, email: userData.email.toLowerCase()});
        }
       setSubmitted(true);
    }

    const hasWhiteSpace = (str) => {
        return /\s/g.test(str);
    }

    const onChange = e => {
        e.preventDefault();
        setUserData({...userData, [e.target.name] :e.target.value });
    }



    const onCheckboxChange = (e) =>{
        setUserData({...userData, [e.target.name] : e.target.checked });
    }
 
    const togglePicker = () =>{
        setShowPicker(!showPicker);
    }

    const randomEmail = () =>{
        return `${moment().format("ddmmYYYY-HHmmss")}@blad.com`;
    }
    const setRandomEmail = (e) =>{
        e.preventDefault();
        setUserData({...userData, email : randomEmail() });
    }

    return (    
        <Form onSubmit={onSubmit}>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Name" name="name" value ={name} onChange={onChange}/>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Last name</Form.Label>
                        <Form.Control type="text" placeholder="Last name" name="lastName" value ={lastName} onChange={onChange}/>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Username</Form.Label>
                        <Form.Control type="text" placeholder="Username" name="username" value ={username} onChange={onChange}/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label> {' ('}<a href="#" onClick={(e)=>setRandomEmail(e)}>Generate random</a>{')'}
                        <Form.Control type="email" placeholder="Email" name="email" value ={email} onChange={onChange} required/>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>password</Form.Label>{' ('}<a href="#" onClick={(e)=>toggleShowPassword(e)}>{showPassword ? 'Hide':'Show'}</a>{')'}
                        <Form.Control type={showPassword ? 'text':'password'} placeholder="password" name="password" value ={password} onChange={onChange}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Role</Form.Label>
                        {isSuperAdmin ?
                         <Form.Select aria-label="Roles" values={role} name="role" onChange={onChange}>
                         <option>{ ROLES[role] }</option>
                         <option value="1" >Super admin</option>
                         <option value="2" >Admin</option>
                         <option value="3" >Client</option>
                         <option value="4" >Delegate</option>
                        </Form.Select>
                        :
                        <Form.Select aria-label="Roles" values={role} name="role" disabled={role === 2 || role === 1} onChange={onChange}>
                            <option>{ ROLES[role] }</option>
                            <option value="3" >Client</option>
                            <option value="4" >Delegate</option>
                        </Form.Select>
                        }   

                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Label>Organisation</Form.Label>
                    <Form.Select aria-label="Default select example" values={organisation} name="organisation" onChange={onChange}>
                        <option>{organisation?.title || '' }</option>
                        {organisations && organisations.map(org => <option key={org._id} value={org._id}>{org.title}</option>)}
                    </Form.Select>
                </Col>
         
                <Col className={(role !== 2 && role !== 1) && 'invisible'}>
                    <Form.Group className="mb-3">
                        <Form.Label>Job title</Form.Label>
                        <Form.Control type="text" placeholder="Job title" name="jobTitle" value ={jobTitle} onChange={onChange}/>
                    </Form.Group>
                </Col>
         
                <Col className={(role !== 2 && role !== 1) && 'invisible'}>
                    <Form.Group className="mb-3">
                    <Form.Label>Is coach</Form.Label>
                        <Form.Check 
                            disabled={role !== 2 && role !== 1}
                            type="switch"
                            name="isCoach"
                            checked={isCoach ?? false}
                            value={isCoach ?? false}
                            onChange= {onCheckboxChange}
                            />     
                    </Form.Group>
                </Col>
                <Col className={(role !== 2 && role !== 1) && 'invisible'}>
                    <Form.Group className="mb-3">
                    <Form.Label>Is project manager</Form.Label>
                        <Form.Check 
                            disabled={role !== 2 && role !== 1}
                            type="switch"
                            name="isProjectManager"
                            checked={isProjectManager ?? false}
                            value={isProjectManager ?? false}
                            onChange= {onCheckboxChange}
                            />     
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                    <Form.Label>Registration open</Form.Label>
                        <Form.Check 

                            type="switch"
                            name="registrationOpen"
                            checked={registrationOpen ?? false}
                            value={registrationOpen ?? false}
                            onChange= {onCheckboxChange}
                            />     
                    </Form.Group>
                </Col>
                
            </Row>

            {(role === 2 || role === 1) &&
                <Button onClick={() => togglePicker()}>{showPicker ? 'Hide logo picker' : 'Show Logo picker'}</Button>
            }
            {showPicker && (role === 2 || role === 1) &&
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Row>

                                {
                                images && images.map(
                                    (img,index) => <UserFormImageItem key={index} image={img} index={index} onImageClick={onImageClick} selectedIndex={selectedIndex} />
                                    )
                                }
                            </Row>

                        </Form.Group>
                    </Col>

                </Row>
            }
    
            <Form.Group className="mb-3">
                <Button variant="primary" type="submit">
                    Submit
                </Button>
                &nbsp;
                <Button onClick={closeForm} className="btn btn-primary my-1">
                    Close
                </Button>   
            </Form.Group>
        </Form>
    )
}

export default UserForm